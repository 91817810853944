<template>
  <div :class="[!props.inline && 'flex flex-col', 'text-base md:text-xl']">
    <strong v-if="$slots.label">
      <slot name="label" />
    </strong>

    {{ props.inline ? "-" : "" }}

    <slot />
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  inline?: boolean;
}>();
</script>
